import React, { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext, AuthProviderProps } from '../../shared/context/AuthContext';
import InterfaceParametersContext, {
  InterfaceParametersProviderProps,
} from '../../shared/context/InterfaceParametersContext';

import { MultiDocQACitationInterface } from '../../shared/Models';
import handleNSXSourceDoc from '../../shared/utils/handleUrlClick';

const MultidocQADocCitation = ({
  article,
  DocumentNumber,
  CitationText,
}: MultiDocQACitationInterface) => {
  const { getTokenAuthHeader } = useContext(AuthContext) as AuthProviderProps;
  const { setMultidocCitationCurrentHover } = useContext(
    InterfaceParametersContext,
  ) as InterfaceParametersProviderProps;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    handleNSXSourceDoc(event, article, undefined, getTokenAuthHeader);
  };

  const handleMouseOver = () => {
    // setting the following state will trigger a focus animation for the
    // search result with the same index as DocumentNumber
    // search results should be referenced in a 0-index basis.
    setMultidocCitationCurrentHover(DocumentNumber);
  };

  const handleMouseOut = () => {
    // setting the following state to -1 will
    // disable any animation for current search results.
    setMultidocCitationCurrentHover(-1);
  };

  let url = article.source_url || article.url;

  return (
    <MultidocQADocAnchor
      href={url}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={(e) => handleClick(e)}
    >
      {CitationText}
    </MultidocQADocAnchor>
  );
};

export default MultidocQADocCitation;

const MultidocQADocAnchor = styled.a`
  color: ${(props) => props.theme.primary};
  text-decoration: none;
`;
