import React from 'react';
import {
  API_BASE,
  DOWNLOADING_TOAST_OPTIONS,
  DOWNLOAD_SUCCESS_TOAST_OPTIONS,
  GLOBAL_TOAST_OPTIONS,
  SEARCH_CLICKED_ENDPOINT,
} from '../Constants';
import { makePOSTRequest } from '../Util';
import { toast } from 'react-toastify';
import { BaseArticle, interactionRequestInterface } from '../Models';

interface ReaderProps {
  done: boolean | undefined;
  value: Uint8Array | undefined;
}

const handleNSXSourceDoc = (
  event: React.MouseEvent<HTMLAnchorElement>,
  article: BaseArticle,
  interactionRequestBody?: interactionRequestInterface,
  getTokenAuthHeader?: () => Promise<HeadersInit>,
) => {
  // Prevent default behavior when clicking a link
  event.preventDefault();
  const anchor = event.currentTarget as HTMLAnchorElement;
  const hasSourceUrl = article.source_url && article.source_url !== '';
  const hasSBRTSource = article.source_database && article.source_database === 'SBRT';

  if (interactionRequestBody && (anchor.href || hasSourceUrl)) {
    //logs click requests in the API
    makePOSTRequest(`${API_BASE}${SEARCH_CLICKED_ENDPOINT}`, interactionRequestBody);
  }

  const downloadFile = async () => {
    let url = anchor.href;
    // SBRT documents do not follow the pattern,
    // even though they have source url annotated, they should be downloaded
    if (hasSBRTSource) {
      url = article.url;
    }

    if (url) {
      const downloadingToastId = toast.info(
        `Downloading "${article.title}"...`,
        DOWNLOADING_TOAST_OPTIONS,
      );
      const response = await fetch(url, {
        headers: getTokenAuthHeader ? await getTokenAuthHeader() : {},
      });
      const reader = response.body?.getReader();

      const contentLength = +Number(response.headers.get('Content-Length'));
      let receivedLength = 0; // received that many bytes at the moment

      const chunks = []; // array of received binary chunks (comprises the body)
      let numberOfNulls = 0; // number of nulls for a chunk
      while (true) {
        const { done, value } = (await reader?.read()) as ReaderProps;
        if (done) {
          break;
        }
        if (value) {
          chunks.push(value);
          receivedLength += value.length;
          toast.update(downloadingToastId, {
            render: `Downloading "${article.title}"... ${Math.round(
              (receivedLength / contentLength) * 100,
            )}%`,
            progress: receivedLength / contentLength,
          });
        } else {
          numberOfNulls += 1;
          if (numberOfNulls > 10) {
            toast.update(downloadingToastId, {
              render: `Error downloading "${article.title}"`,
              type: toast.TYPE.ERROR,
              ...GLOBAL_TOAST_OPTIONS,
            });
            return;
          }
        }
      }
      const chunksArray = new Uint8Array(receivedLength);

      let position = 0;
      for (let chunk of chunks) {
        chunksArray.set(chunk, position);
        position += chunk.length;
      }

      let blob = new Blob([chunksArray as Uint8Array], {
        type: response.headers.get('Content-Type') as string,
      });
      let fileURL = URL.createObjectURL(blob);
      if (response.headers.get('Content-Type')?.includes('pdf') && article.page_index) {
        fileURL = fileURL + `#page=${article.page_index}`;
      }

      toast.update(downloadingToastId, {
        render: (
          <>
            Open:
            <a href={fileURL} target="_blank" rel="noopener noreferrer">{`${article.title}`}</a>
          </>
        ),
        type: toast.TYPE.SUCCESS,
        ...DOWNLOAD_SUCCESS_TOAST_OPTIONS,
      });

      let openedWindow = window.open(fileURL, '_blank');
      // Detect if the window was blocked by the browser (pop-ups)
      if (!openedWindow || openedWindow.closed || typeof openedWindow.closed == 'undefined') {
        toast.warn(
          `Please allow pop-ups for this site to open "${article.title}"`,
          DOWNLOADING_TOAST_OPTIONS,
        );
      }
    }
  };

  // SBRT documents break the common rule, even though they have a source url
  // the desired behaviour is to download the source file
  if (hasSourceUrl && !hasSBRTSource) {
    const url = anchor.href;
    window.open(url, '_blank');
  } else {
    downloadFile();
  }
};

export default handleNSXSourceDoc;
