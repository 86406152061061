import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Footer from './components/navigation/Footer';
import HomePage from './components/pages/HomePage/HomePage';
import MagicWikiSocket from './components/pages/MagicWiki/MagicWikiSocket';
import NotFoundPage from './components/pages/NotFoundPage';
import { AuthProvider } from './shared/context/AuthContext';
import { InterfaceParametersProvider } from './shared/context/InterfaceParametersContext';
import { SearchBarProvider } from './shared/context/SearchBarContext';
import {
  HOME_ROUTE,
  SEBRAE_ROUTE,
  MAGICWIKI_ROUTE,
  PAGE_FAVICON,
  PAGE_DESCRIPTION,
  QUEST_RESULT_ROUTE,
} from './shared/Constants';
import Theme from './shared/Theme';
import QuestHome from './components/pages/Quest/QuestHome/QuestHome';
import QuestSearchResult from './components/pages/Quest/QuestSearchResult/QuestSearchResult';
import { QuestSearchProvider } from './components/pages/Quest/QuestSearchResultContext';
import { QuestIndexProvider } from './components/pages/Quest/QuestIndexContext';
import { Auth0Prov } from './Auth0Provider';

const App = () => {
  return (
    <>
      <ThemeProvider theme={Theme}>
        {/* Dynamically load metadata for HTML header */}
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={PAGE_DESCRIPTION} />
          <link rel="icon" href={PAGE_FAVICON} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
          ></meta>
          <style>{`body { background-color: ${Theme.backgroundSoftWhite}; }`}</style>
        </Helmet>
        <Router>
          <AppContainer>
            <Auth0Prov>
              <AuthProvider>
                <InterfaceParametersProvider>
                  <SearchBarProvider>
                    <QuestSearchProvider>
                      <QuestIndexProvider>
                        <Switch>
                          <Route exact path={HOME_ROUTE}>
                            <HomePage
                              title="NeuralsearchX"
                              showUploadCard={true}
                              default_index="web"
                              simpleSearchBar={false}
                              showGraph={true}
                            />
                            <Footer title="NeuralsearchX" />
                          </Route>
                          <Route exact path={SEBRAE_ROUTE}>
                            <QuestHome />
                          </Route>
                          <Route exact path={QUEST_RESULT_ROUTE}>
                            <QuestSearchResult homeQuestUrl={SEBRAE_ROUTE} />
                          </Route>
                          <Route exact path={MAGICWIKI_ROUTE}>
                            <MagicWikiSocket title="MagicWiki" simpleSearchBar={true} />
                            <Footer title="MagicWiki" />
                          </Route>
                          <Route>
                            <NotFoundPage />
                          </Route>
                        </Switch>
                      </QuestIndexProvider>
                    </QuestSearchProvider>
                  </SearchBarProvider>
                </InterfaceParametersProvider>
              </AuthProvider>
            </Auth0Prov>
          </AppContainer>
        </Router>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default App;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
