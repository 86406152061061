import React, { useRef, useContext } from 'react';
import styled, { css } from 'styled-components';

import { AuthContext, AuthProviderProps } from '../../../shared/context/AuthContext';
import { BodySmall, FadeInText } from '../../../shared/Styles';
import { parseAbstract } from '../../../shared/Util';
import ArticleInfoGeneric from './ArticleInfoGeneric';
import { SearchResultProps } from '../../common/BaseSearchResult';
import FeedbackButtons from '../../common/FeedbackButtons';
import InterfaceParametersContext, {
  InterfaceParametersProviderProps,
} from '../../../shared/context/InterfaceParametersContext';
import { adjustHighlights, highlightText } from './highlighter';
import handleNSXSourceDoc from '../../../shared/utils/handleUrlClick';

const SearchResultGeneric = ({
  article,
  emphasis,
  position,
  queryId,
  query,
  queryTokens,
  isReranked,
  searchedIndex,
}: SearchResultProps) => {
  const fullTextRef = useRef(null);
  const { getTokenAuthHeader } = useContext(AuthContext) as AuthProviderProps;

  const { evaluationMode, multidocCitationCurrentHover } = useContext(
    InterfaceParametersContext,
  ) as InterfaceParametersProviderProps;

  // Separate snippet from other paragraphs if it was highlighted
  const originalSnippet = article.paragraphs[0] || '';

  const snippet = parseAbstract(originalSnippet);
  const snippetHighlights = adjustHighlights(
    article.highlights[0],
    snippet.length - originalSnippet.length,
  );

  const interactionRequestBody = {
    query_id: queryId,
    query_text: query,
    result_id: article.id,
    position,
    index: searchedIndex,
    source_database: article.source_database,
    source_url: article.source_url,
  };

  return (
    <SearchResultWrapper>
      <ResultArea
        emphasis={emphasis}
        multidocHoverIndication={multidocCitationCurrentHover === position}
      >
        <ArticleInfoGeneric
          article={article}
          position={position}
          onClickTitle={(event) =>
            handleNSXSourceDoc(event, article, interactionRequestBody, getTokenAuthHeader)
          }
        />
        <div ref={fullTextRef}>
          {/* Display snippet */}
          {snippet && (
            <Paragraph marginBottom={16}>
              {highlightText(snippet, snippetHighlights, queryTokens, article.content_is_formated)}
            </Paragraph>
          )}
        </div>
      </ResultArea>

      {evaluationMode > 0 && (
        <FeedbackButtons
          query={query}
          docID={article.id}
          pos={position}
          isReranked={isReranked}
          searchedIndex={searchedIndex}
        />
      )}
    </SearchResultWrapper>
  );
};

export default SearchResultGeneric;

const ResultArea = styled.div<{
  emphasis: boolean;
  multidocHoverIndication: boolean;
}>`
  display: flex;
  flex-direction: ${({ emphasis }) => (emphasis ? 'column-reverse' : 'column')};
  border: ${({ multidocHoverIndication, theme }) =>
    multidocHoverIndication ? '3px solid ' + theme.primary : '0px'};
  padding: ${({ multidocHoverIndication }) => (multidocHoverIndication ? '0.2rem 1rem' : '0')};
  transition: 0.3s ease-out;
`;

const SearchResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 12px 0;
  border-bottom: 1px dotted ${({ theme }) => theme.lightGrey};
`;

const fadeInAnimation = css`animation ${FadeInText} 0.5s ease-in-out;`;

const ResultText = styled.div<{
  marginTop?: number;
  marginBottom?: number;
}>`
  ${BodySmall}
  color: ${({ theme }) => theme.darkGrey};
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline;

  & > .showCollapsed {
    opacity: 1;
    display: inline;
    ${() => fadeInAnimation}
  }

  & > .hideCollapsed {
    opacity: 0;
    display: none;
  }

  & > .highlight {
    background: none;
  }
`;

const Paragraph = styled(ResultText)`
  word-wrap: break-word;
  font-size: 0.875rem;
`;
