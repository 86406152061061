import { Auth0Provider } from '@auth0/auth0-react';
import React, { FC, ReactNode } from 'react';

interface Auth0ProviderProps {
  children: ReactNode;
}

export const Auth0Prov: FC<Auth0ProviderProps> = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window.location.origin;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
