import React, { useContext } from 'react';
import styled from 'styled-components';

import { Heading3 } from '../../../shared/Styles';
import InterfaceParametersContext, {
  InterfaceParametersProviderProps,
} from '../../../shared/context/InterfaceParametersContext';
import { ArticleInfoProps } from '../../common/BaseSearchResult';
import { SOURCE_LENGTH } from '../../../shared/Constants';
const ArticleInfoGeneric: React.FC<ArticleInfoProps> = ({
  article,
  position,
  onClickTitle = () => {},
  boldTitle = false,
}) => {
  const { evaluationMode } = useContext(
    InterfaceParametersContext,
  ) as InterfaceParametersProviderProps;
  // if article_source exist, use it as url, otherwise try to use article.url
  const hasSource =
    (article.source_url && article.source_url !== '') ||
    (article.source_database && article.source_database !== '');
  let url = article.source_url || article.url;
  // if the source database is SBRT, use download url instead of source
  const sourceText = article.source_url || `Source: ${article.source_database}`;
  const formatedSourceText =
    hasSource && sourceText.length > SOURCE_LENGTH
      ? sourceText.slice(0, SOURCE_LENGTH) + '...'
      : sourceText;
  const hasURL = url && url !== '';

  return (
    <ArticleInfoWrapper
      href={url}
      disabled={true}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(event) => onClickTitle(event)}
    >
      {hasSource && <Source className="ArticleSource">{formatedSourceText}</Source>}
      <TitleArea bold={boldTitle} hasUrl={hasURL}>
        {position !== undefined && evaluationMode > 0 ? `${position + 1}. ` : ''}
        {url && url !== null && url !== '' ? (
          <Title className="ArticleTitle">{article.title}</Title>
        ) : (
          article.title
        )}
      </TitleArea>
    </ArticleInfoWrapper>
  );
};

export default ArticleInfoGeneric;

export const ArticleInfoWrapper = styled.a<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'pointer' : 'auto')};
  width: fit-content;
  text-decoration: none;
  ${({ disabled }) => (disabled ? '' : 'pointer-events: none;')}
  &:hover .ArticleTitle {
    color: ${({ theme }) => theme.secondary};
    text-decoration: underline;
  }
`;

export const TitleArea = styled.div<{ bold?: boolean; hasUrl: boolean }>`
  ${Heading3}
  color: ${({ theme, hasUrl }) => (hasUrl ? theme.primary : theme.black)};
  margin-bottom: 3px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  font-size: 1.2rem;
  word-break: break-word;
`;

export const Title = styled.span`
  text-decoration: none;
  word-break: break-word;
`;

export const Source = styled.span`
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: none;
  word-break: break-word;
  color: ${({ theme }) => theme.black};
`;

export const Subtitle = styled.div`
  font-size: 1rem;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.black};
`;

export const Authors = styled.span`
  margin-right: 4px;
`;

export const Journal = styled.span`
  font-style: italic;
  margin-right: 4px;
`;
