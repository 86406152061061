import React from 'react';
import styled from 'styled-components';
import MultidocQADocCitation from './MultidocQADocCitation';
import { MultiDocQAData } from '../../shared/Models';

const MultiDocQAComponent = ({ predAnswer, explanation, language, articles }: MultiDocQAData) => {
  const answer_text = language === 'en' ? 'Answer' : 'Resposta';
  const explanation_text = language === 'en' ? 'Explanation' : 'Explicação';

  const parseDocCitations = (explanation: string) => {
    // the following regex matches [X] where X is a number
    const regex = /\[\d+\]/g;
    const matches = Array.from(explanation.matchAll(regex));

    let currentPos = 0;
    const sections = [];
    for (const regexMatch of matches) {
      const citationSpanBegin = regexMatch.index as number;
      const citationSpanEnd = (regexMatch.index as number) + regexMatch[0].length;
      const citationText = explanation.substring(citationSpanBegin, citationSpanEnd);
      // if there is text between the first citation and the current position, wrap
      // this text in a simple span tag
      if (citationSpanBegin !== currentPos) {
        sections.push(<span>{explanation.substring(currentPos, citationSpanBegin)}</span>);
      }

      const mentionedDocNumber = Number(citationText.match(/\d+/));
      const referenced_article = articles[mentionedDocNumber - 1];
      // wrap the citation in the MultidocQADocCitation component
      sections.push(
        <MultidocQADocCitation
          DocumentNumber={mentionedDocNumber - 1}
          CitationText={citationText}
          article={referenced_article}
        />,
      );
      // adjust the current position of the parsing
      currentPos = citationSpanEnd;
    }

    // ensures that all the text in the end of the explanation (i.e after all citations)
    // is also included
    sections.push(<span>{explanation.substring(currentPos, explanation.length)}</span>);

    return sections;
  };

  return (
    <MultiDocQAContainer>
      <h3>{answer_text}</h3>
      <MultiDocQAContent>{predAnswer}</MultiDocQAContent>
      <h3>{explanation_text}</h3>
      <MultiDocQAContent>{parseDocCitations(explanation)}</MultiDocQAContent>
    </MultiDocQAContainer>
  );
};

export default MultiDocQAComponent;

const MultiDocQAContainer = styled.div`
  padding: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;

  height: 100%;

  align-items: left;
  border: 0.1px solid #ccc;
  border-radius: 10px 10px 10px 10px;

  @media screen and (max-width: 2600px) and (min-width: 500px) {
    max-width: 50%;
  }
  @media screen and (min-width: 2600px) {
    max-width: 1100px;
  }
`;

const MultiDocQAContent = styled.p`
  text-align: left;
  margin: 0;
  font-size: 0.875rem;
  color: #555555;
`;
