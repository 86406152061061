import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import {
  API_BASE,
  INDEX_ENDPOINT,
  SEARCH_ENDPOINT,
  GLOBAL_TOAST_OPTIONS,
  LARGE_MOBILE_BREAKPOINT,
} from '../../shared/Constants';
import { useForm } from 'react-hook-form';
import { Dialog, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { AuthContext, AuthProviderProps } from '../../shared/context/AuthContext';
import SearchBarContext, { SearchBarProviderProps } from '../../shared/context/SearchBarContext';

export interface UploadDialogComponentProps {
  showDialog: boolean;
  setShowDialog: (s: boolean) => void;
}

const UploadDialogComponent = ({ showDialog, setShowDialog }: UploadDialogComponentProps) => {
  const { register, handleSubmit, setValue } = useForm();
  const [files, setFiles] = useState<File[]>([]);
  const [loadingFilesToApi, setLoadingFilesToApi] = useState<boolean>(false);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: [
      'application/zip',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/plain',
    ],
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
  });

  const { getTokenAuthHeader, getResponse } = useContext(AuthContext) as AuthProviderProps;
  const { setAvailableIndices } = useContext(SearchBarContext) as SearchBarProviderProps;

  const checkAccept = async (data: any) => {
    const formData = new FormData();

    if (files.length === 0) {
      toast.error('Please, provide at least one file to upload', GLOBAL_TOAST_OPTIONS);
      return;
    }

    // Check if the index name is valid (only alphanumeric characters or underscores)
    const regex = /^[a-zA-Z0-9_]*$/;
    if (!regex.test(data.index_name)) {
      toast.error(
        'The collection name must only contain alphanumeric characters or underscores',
        GLOBAL_TOAST_OPTIONS,
      );
      return;
    }

    setLoadingFilesToApi(true);
    for (const file in acceptedFiles) {
      formData.append('files', acceptedFiles[file]);
    }
    formData.append('index_name', data.index_name);
    formData.append('detect_language', 'true');

    const header = await getTokenAuthHeader();
    const url = `${API_BASE}${INDEX_ENDPOINT}`;
    const response = await getResponse(url, 'POST', header, formData);

    if (response.status === 201) {
      closeAndCleanDialog();
      toast.success(
        'We are creating your collection, this may take a couple of minutes',
        GLOBAL_TOAST_OPTIONS,
      );
      /*
      We use a default fetch here instead of the getResponse function since both uploadDialog
      and homepage would try to handle token errors on different threads with different
      variable states, causing wrong requests. (only one of them must solve the problem)
      */
      let response = await fetch(`${API_BASE}${SEARCH_ENDPOINT}/datasets`, {
        method: 'GET',
        headers: header,
      });
      if (response.status === 200) {
        const data = await response.json();
        const { indices } = data;
        setAvailableIndices(indices);
      }
    } else if (response.status === 413) {
      toast.error(
        'It appears your collection is too large. The maximum allowed size is 1 GB',
        GLOBAL_TOAST_OPTIONS,
      );
    } else if (response.status === 409) {
      toast.error('This collection name is already in use', GLOBAL_TOAST_OPTIONS);
      setValue('index_name', '');
    } else {
      toast.error('Unexpected error. Try again in a few minutes 😞', GLOBAL_TOAST_OPTIONS);
    }
    setLoadingFilesToApi(false);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const closeAndCleanDialog = () => {
    setFiles([]);
    setValue('index_name', '');
    closeDialog();
  };

  useEffect(() => {
    setValue('index_name', '');
  }, [setValue]);

  return (
    <UploadDialog
      open={showDialog}
      onClose={closeDialog}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <CloseDialogButton onClick={closeDialog} />
      <UploadPageContainer>
        <Title> Upload your own documents </Title>
        <FormContainer onSubmit={handleSubmit(checkAccept)}>
          <FormField>
            <span> Collection name </span>
            <IndexNameInput
              required
              {...register('index_name')}
              placeholder="Your collection name"
            />
          </FormField>
          <DropzoneDiv {...getRootProps()} isDragActive={isDragActive} isDragReject={isDragReject}>
            <input {...register('files')} {...getInputProps()} />
            <p>
              Drag 'n' drop some files here, or click to select files (txt, docx, pdf or a zip
              containing files in the previously mentioned formats)
            </p>
            {files.length !== 0 && (
              <SelectedFilesList>
                {files.map((file) => {
                  return <span key={file.name}> {file.name} </span>;
                })}
              </SelectedFilesList>
            )}
          </DropzoneDiv>
          <FormButtonArea>
            <FormButton type="submit"> Send </FormButton>
            <FormButton type="button" onClick={() => setFiles([])}>
              Clear
            </FormButton>
          </FormButtonArea>
          {loadingFilesToApi && <LoadingCircularProgress />}
        </FormContainer>
      </UploadPageContainer>
    </UploadDialog>
  );
};

export default UploadDialogComponent;

const UploadPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingCircularProgress = styled(CircularProgress)`
  margin-top: 12px;
`;

const CloseDialogButton = styled(CloseIcon)`
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
`;

const UploadDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 80vw;
  }

  & > .MuiDialog-container {
    width: 100%;
    padding: 20px;
  }

  & .MuiDialogPaper {
  }

  & > .MuiDialog-container > .MuiPaper-root {
    width: 100%;
    padding: 20px;
  }
`;
const FormField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
  max-width: 75vw;

  & span {
    min-width: 25%;
  }
  @media only screen and (max-width: ${LARGE_MOBILE_BREAKPOINT}px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
const SelectedFilesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  & button + button {
    margin-left: 20px;
    background: ${({ theme }) => theme.grey};
  }
`;

interface DropzoneProps {
  isDragActive: boolean;
  isDragReject: boolean;
}
const FormContainer = styled.form`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IndexNameInput = styled.input`
  width: 30%;
  margin-left: 12px;
  flex: 1;
  padding: 12px 16px;
  outline: none;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.grey};

  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
  @media only screen and (max-width: ${LARGE_MOBILE_BREAKPOINT}px) {
    width: auto;
  }
`;

const FormButton = styled.button`
  display: flex;
  background: ${({ theme }) => theme.primary};
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.white};
  border-radius: 4px;
  outline: none;
  transition: background 0.1s;

  &:hover,
  &:focus {
    filter: brightness(80%);
  }
`;
const DropzoneDiv = styled.div<DropzoneProps>`
  border: 4px dotted black;
  margin-top: 10px;
  width: 100%;
  max-width: 80vw;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &:hover {
    cursor: pointer;
  }
  ${(props) => props.isDragActive && 'border-color:green;'}
  ${(props) => props.isDragReject && 'border-color:red;'}
`;

const Title = styled.h1`
  margin-top: 20px;

  font-size: 30px;
  @media only screen and (max-width: ${LARGE_MOBILE_BREAKPOINT}px) {
    font-size: 20px;
    text-align: center;
  }
`;
