import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { toast } from 'react-toastify';
import Card from '@material-ui/core/Card';
import { useAuth0 } from '@auth0/auth0-react';

import { PageWrapper, PageContent, Heading2, BodySmall } from '../../../shared/Styles';
import Loading from '../../common/Loading';
import SearchBar from './SearchBar';
import Navbar from '../../navigation/Navbar';
import MultiDocQAComponent from '../../multidocqa/Multidocqa';
import SearchBarContext, { SearchBarProviderProps } from '../../../shared/context/SearchBarContext';
import EvaluationCard from '../../common/EvaluationCard';
import { Helmet } from 'react-helmet';
import { tokenize } from '../../../shared/Util';
import {
  API_BASE,
  SEARCH_ENDPOINT,
  SearchIndexOption,
  GLOBAL_TOAST_OPTIONS,
  TABLET_BREAKPOINT,
  LOCALSTORAGE_COOKIES_AGREEMENT,
  LOCALSTORAGE_NSX_GIVEN_EVALUATION,
  MULTIDOCQA_ENDPOINT,
  USER_REGISTER_ENDPOINT,
} from '../../../shared/Constants';
import { SearchArticle, MultiDocQADataAPIResponse } from '../../../shared/Models';
import { AuthContext, AuthProviderProps } from '../../../shared/context/AuthContext';
import InterfaceParametersContext, {
  InterfaceParametersProviderProps,
} from '../../../shared/context/InterfaceParametersContext';
import SearchResultGeneric from '../../dataset/generic/SearchResultsGeneric';
import UploadDialog from '../../common/UploadDialog';
import AuthDialog from '../../common/AuthDialog';
import CookiesDialog from '../../common/CookiesDialog';
import BoschLogo from '../../../img/bosch_logo.png';
import BoschLine from '../../../img/boschline.png';
import MaintenanceSnackBar from '../../common/MaintenanceSnackBar';

export interface HomePageProps {
  title: string;
  showUploadCard: boolean;
  default_index: string;
  simpleSearchBar: boolean;
  showGraph: boolean;
}

const HomePage = ({ title, showUploadCard, default_index, simpleSearchBar }: HomePageProps) => {
  //context variables
  const { user, getTokenAuthHeader, getResponse } = useContext(AuthContext) as AuthProviderProps;

  const {
    showComparison,
    setShowComparison,
    availableIndices,
    setAvailableIndices,
    isChecked,
    email,
  } = useContext(SearchBarContext) as SearchBarProviderProps;

  const {
    evaluationMode,
    setEvaluationMode,
    highlightDocuments,
    setHighlightDocuments,
    promoteDocuments,
    setPromoteDocuments,
    multidocqaDocuments,
    setMultidocqaDocuments,
    devMode,
    setDevMode,
  } = useContext(InterfaceParametersContext) as InterfaceParametersProviderProps;

  const urlParams = new URLSearchParams(useLocation().search);
  const query = urlParams.get('query') || '';
  //this parameters is used to signal that a new request should be made, even if the query is the same
  const refreshNumber = urlParams.get('refresh') || '';
  const evaluationModeValueFromQuery = urlParams.get('evaluation_mode');
  const evaluationModeFromQuery =
    evaluationModeValueFromQuery != null ? parseInt(evaluationModeValueFromQuery) : 0;
  const promoteDocumentsFromQuery = urlParams.get('promote_documents') === 'true';
  const multidocqaDocumentsFromQuery = urlParams.get('multidocqa') !== 'false';
  const devModeValueFromQuery = urlParams.get('dev_mode');
  const devModeFromQuery = devModeValueFromQuery != null ? parseInt(devModeValueFromQuery) : 0;
  const highlightFromQuery = urlParams.get('highlight_documents');
  const hasQuery = query !== null && query !== '';
  const datasetsAreLoaded = Object.keys(availableIndices).length !== 0;
  const isHighlightDocumentsValid =
    highlightFromQuery === 'true' || highlightFromQuery === 'false' || highlightFromQuery === null;

  if (evaluationModeFromQuery !== evaluationMode) {
    setEvaluationMode(evaluationModeFromQuery);
  }

  if (promoteDocumentsFromQuery !== promoteDocuments) {
    setPromoteDocuments(promoteDocumentsFromQuery);
  }

  if (multidocqaDocumentsFromQuery !== multidocqaDocuments) {
    setMultidocqaDocuments(multidocqaDocumentsFromQuery);
  }

  if (devModeFromQuery !== devMode) {
    setDevMode(devModeFromQuery);
  }

  //state variables
  const [language, setLanguage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [loadingMultiDocQA, setLoadingMultiDocQA] = useState<boolean>(false);
  const [randomState, setRandomState] = useState<number>(1);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showAuthDialog, setShowAuthDialog] = useState<boolean>(false);
  const [showMaintenanceSnackBar, setShowMaintenanceSnackBar] = useState<boolean>(
    process.env.REACT_APP_SCHEDULED_MAINTENANCE === 'true',
  );
  const [showCookiesDialog, setShowCookiesDialog] = useState<boolean>(() => {
    return localStorage.getItem(LOCALSTORAGE_COOKIES_AGREEMENT) !== 'true';
  });
  const [showEvaluationCard, setShowEvaluationCard] = useState<boolean>(() => {
    if (title === 'Quest') {
      // currently we show the evaluation card for all querys in quest mode
      return true;
    } else {
      return localStorage.getItem(LOCALSTORAGE_NSX_GIVEN_EVALUATION) !== 'true';
    }
  });
  const [queryInputText, setQueryInputText] = useState<string>(query || '');
  //making copy of selected index to avoid adding the query examples in the state
  const [searchIndex, setSelectedSearchIndex] = useState<SearchIndexOption>({
    value: '',
    label: '',
    visible: false,
  });
  const [queryId, setQueryId] = useState<string>('');
  const [searchResultsReranker, setSearchResultsReranker] = useState<SearchArticle[] | null>(null);
  const [searchResultsReference, setSearchResultsReference] =
    useState<SearchArticle[] | null>(null);
  const [searchResultsMultiDocQA, setSearchResultsMultiDocQA] =
    useState<MultiDocQADataAPIResponse | null>(null);
  const [ellapsedTime, setEllapsedTime] = useState<number>(0);

  const { user: auth0User, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (evaluationMode >= 1) {
      setShowComparison(true);
    }
  }, [evaluationMode, setShowComparison]);

  useEffect(() => {
    if (!isHighlightDocumentsValid) {
      toast.warning(
        'document highlighting was requested but the backend does not support it.',
        GLOBAL_TOAST_OPTIONS,
      );
    } else if (highlightFromQuery) {
      setHighlightDocuments(highlightFromQuery === 'true');
    }
  }, [highlightFromQuery, isHighlightDocumentsValid, setHighlightDocuments]);

  useEffect(() => {
    const fetchData = async () => {
      let headers = {};
      headers = await getTokenAuthHeader();
      const response = await getResponse(`${API_BASE}${USER_REGISTER_ENDPOINT}`, 'POST', headers);
      // if the response status is not 200
      if (response.status !== 200) {
        toast.error(
          'Sorry, there was an error while authenticating your user. Please try again later.',
          GLOBAL_TOAST_OPTIONS,
        );
      }
    };
    if (isAuthenticated && !isLoading) {
      fetchData();
    }
  }, [isAuthenticated, auth0User, getResponse, getTokenAuthHeader, isLoading]);

  useEffect(
    () => {
      const fetchData = async () => {
        if (isLoading) {
          setLoading(true);
        }
        let headers = {};
        if (user.token) {
          headers = await getTokenAuthHeader();
        }
        const response = await getResponse(
          `${API_BASE}${SEARCH_ENDPOINT}/datasets`,
          'GET',
          headers,
        );

        if (response.status === 200) {
          const data = await response.json();
          const { indices } = data;
          setAvailableIndices(indices);
        }
        setLoading(false);
      };
      if (!showCookiesDialog) {
        fetchData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, auth0User, showCookiesDialog],
  );

  useEffect(() => {
    const index = urlParams.get('index') || '';
    if (availableIndices[index]) {
      setSelectedSearchIndex({
        value: availableIndices[index].name,
        label: availableIndices[index].label,
        visible: availableIndices[index].visible,
      });
    } else if (availableIndices[default_index]) {
      setSelectedSearchIndex({
        value: availableIndices[default_index].name,
        label: availableIndices[default_index].label,
        visible: availableIndices[default_index].visible,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableIndices]);

  useEffect(() => {
    setQueryInputText(query);
    const randomNumber = Math.random();
    setRandomState(randomNumber);
  }, [query, refreshNumber]);

  useEffect(() => {
    const fetchData = async (query: string | null) => {
      if (query === null || query === '') {
        setLoading(false);
        setSearchResultsReranker([]);
        setSearchResultsReference([]);
        return;
      }

      //if index value is not available yet, set results to empty and wait for next useEffect call
      if (searchIndex.value === '') {
        setSearchResultsReranker([]);
        setSearchResultsReference([]);
        return;
      }
      try {
        setLoading(true);
        setSearchResultsReranker(null);
        setSearchResultsMultiDocQA(null);

        const startTimestamp = performance.now();
        let headers: any = {};
        headers = await getTokenAuthHeader();
        headers.isChecked = isChecked;
        headers.email = email;
        let response = await getResponse(
          `${API_BASE}${SEARCH_ENDPOINT}?index=${encodeURIComponent(
            searchIndex.value,
          )}&query=${encodeURIComponent(
            query.toLowerCase(),
          )}&return_reference=${evaluationMode}&highlight_documents=${highlightDocuments}&promote_documents=${promoteDocuments}&multidocqa=${multidocqaDocuments}`,
          'GET',
          headers,
        );

        if (response.status === 200) {
          const endTimestamp = performance.now();
          // Set ellapsed time in seconds, rounded by hundredths (e.g. "0.23 seconds")
          setEllapsedTime(Math.round((endTimestamp - startTimestamp) / 10) / 100);

          const { query_id, response_reranker, response_reference, language } =
            await response.json();
          setSearchResultsReranker(response_reranker);
          setSearchResultsReference(response_reference);
          setQueryId(query_id);
          setLoading(false);

          if (
            response_reranker !== null &&
            urlParams.get('evaluation_mode') === '0' &&
            urlParams.get('multidocqa') === 'true'
          ) {
            var body = {
              query: query.toLowerCase(),
              documents: response_reranker,
              language: language,
              index: searchIndex.value,
            };

            try {
              setLoadingMultiDocQA(true);

              let multidoc_response = await getResponse(
                `${API_BASE}${MULTIDOCQA_ENDPOINT}`,
                'POST',
                {
                  ...(await getTokenAuthHeader()),
                  'Content-Type': 'application/json',
                },
                JSON.stringify(body),
              );

              if (multidoc_response.ok) {
                multidoc_response.json().then((data) => {
                  setLanguage(language);
                  setSearchResultsMultiDocQA(data);
                  setLoadingMultiDocQA(false);
                });
              } else {
                setLoadingMultiDocQA(false);
                toast.warning(
                  'Sorry, but the question answering feature is currently disabled.',
                  GLOBAL_TOAST_OPTIONS,
                );
              }
            } catch (error) {
              setLoadingMultiDocQA(false);
            }
          }
        } else if (response.status === 503) {
          // index not yet ready on the server
          toast.info(
            "This collection hasn't been fully processed yet, please try again in a few minutes",
          );
          throw new Error("This collection hasn't been fully processed yet");
        } else {
          toast.error('Unexpected error. Try again in a few minutes 😞', GLOBAL_TOAST_OPTIONS);
          throw new Error('Unexpected error. Try again in a few minutes');
        }
      } catch (err) {
        setLoading(false);
        setSearchResultsReranker([]);
        setSearchResultsReference([]);
      }
    };
    if (!showCookiesDialog) {
      fetchData(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchIndex.value, refreshNumber, showCookiesDialog]);

  const queryTokens = tokenize(query);

  const resultsCount = searchResultsReranker !== null ? searchResultsReranker.length : 0;
  const hasResults = query && resultsCount > 0;

  const showBothSearchResults: boolean = !!query && showComparison;
  const placeholderText = title === 'Quest' ? 'Empreendedor, faça sua pergunta.' : undefined;

  let leftColumnData = searchResultsReranker;
  let rightColumnData = searchResultsReference;
  let isLeftColumnReranked = true;
  let isRightColumnReranked = false;
  if (evaluationMode && showBothSearchResults) {
    leftColumnData = randomState > 0.5 ? searchResultsReranker : searchResultsReference;
    rightColumnData = randomState > 0.5 ? searchResultsReference : searchResultsReranker;
    isLeftColumnReranked = randomState > 0.5 ? true : false;
    isRightColumnReranked = randomState > 0.5 ? false : true;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageWrapper>
        <Navbar setShowAuthDialog={setShowAuthDialog} title={title} />
        <HomePageContent hasQuery={hasQuery} adjustBosch={searchIndex.value === 'bosch'}>
          {!query && title === 'Quest' && (
            <QuestHomepageText>Tire suas dúvidas técnicas ou empresariais</QuestHomepageText>
          )}
          {/* TODO: remove bosch visuals after trial */}
          {searchIndex.value === 'bosch' && (
            <>
              <BoschLineImg src={BoschLine} alt="bosch line"></BoschLineImg>
              <BoschLogoArea>
                <img src={BoschLogo} alt="bosch logo"></img>
                <span> consultax</span>
              </BoschLogoArea>
            </>
          )}
          <SearchBar
            query={queryInputText}
            searchIndex={searchIndex}
            simpleSearchBar={simpleSearchBar}
            hasResultsInDisplay={(resultsCount > 0 || loading) && query !== ''}
            setQuery={setQueryInputText}
            setSearchIndex={setSelectedSearchIndex}
            setShowUploadDialog={setShowUploadDialog}
            placeholder={placeholderText}
          />
          {showEvaluationCard && query && !loading && (
            <EvaluationCard title={title} setShowEvaluationCard={setShowEvaluationCard} />
          )}
          {!query && !loading && (
            <OpenUploadDialogContainer>
              {showUploadCard && (
                <OpenUploadDialogCard variant="outlined">
                  <OpenUploadDialogText>
                    Search your own documents with state-of-art machine learning!
                  </OpenUploadDialogText>
                  <DialogButtonsArea>
                    <OpenUploadDialogButton
                      onClick={() => {
                        if (!isAuthenticated) {
                          toast.error(
                            'You must be logged in to upload files',
                            GLOBAL_TOAST_OPTIONS,
                          );
                        } else {
                          setShowUploadDialog(true);
                        }
                      }}
                    >
                      Upload documents
                    </OpenUploadDialogButton>
                  </DialogButtonsArea>
                </OpenUploadDialogCard>
              )}
            </OpenUploadDialogContainer>
          )}
          {hasResults && (
            <ResultCount>{`Found ${resultsCount} results (${ellapsedTime} seconds)`}</ResultCount>
          )}
          <ErrorBoundary FallbackComponent={() => <NoResults>Error retrieving results</NoResults>}>
            {loading && <Loading />}
            <HomeContent showComparison={showBothSearchResults}>
              {!query && <div></div>}
              {query &&
                !loading &&
                (!hasResults && datasetsAreLoaded ? (
                  <NoResults>No results found</NoResults>
                ) : (
                  <>
                    <LeftContent showComparison={showBothSearchResults}>
                      {showBothSearchResults && !evaluationMode && (
                        <SectionContentTitle>Our method</SectionContentTitle>
                      )}
                      <SearchResultsArea>
                        {leftColumnData !== null &&
                          leftColumnData.map((article, i) => (
                            <SearchResultGeneric
                              key={article.id}
                              emphasis={i === 0}
                              article={article}
                              position={i}
                              queryTokens={queryTokens}
                              queryId={queryId}
                              query={queryInputText}
                              isReranked={isLeftColumnReranked}
                              searchedIndex={searchIndex.value}
                            />
                          ))}
                      </SearchResultsArea>
                    </LeftContent>
                    {showBothSearchResults && (
                      <RightContent>
                        <SearchResultsArea>
                          {!evaluationMode && (
                            <SectionContentTitle>Industry Standard</SectionContentTitle>
                          )}
                          {rightColumnData !== null &&
                            rightColumnData.map((article, i) => (
                              <SearchResultGeneric
                                key={article.id}
                                emphasis={i === 0}
                                article={article}
                                position={i}
                                queryTokens={queryTokens}
                                queryId={queryId}
                                query={queryInputText}
                                isReranked={isRightColumnReranked}
                                searchedIndex={searchIndex.value}
                              />
                            ))}
                        </SearchResultsArea>
                      </RightContent>
                    )}
                    {loadingMultiDocQA && <Loading />}
                    {searchResultsMultiDocQA && searchResultsReranker && (
                      <MultiDocQAComponent
                        predAnswer={searchResultsMultiDocQA['pred_answer']}
                        explanation={searchResultsMultiDocQA['explanation']}
                        language={language}
                        articles={searchResultsReranker}
                      />
                    )}
                  </>
                ))}
              <MaintenanceSnackBar
                language={navigator.language}
                showSnackBar={showMaintenanceSnackBar}
                setShowSnackBar={setShowMaintenanceSnackBar}
              />
              <UploadDialog showDialog={showUploadDialog} setShowDialog={setShowUploadDialog} />
              <AuthDialog showDialog={showAuthDialog} setShowDialog={setShowAuthDialog} />
              <CookiesDialog showDialog={showCookiesDialog} setShowDialog={setShowCookiesDialog} />
            </HomeContent>
          </ErrorBoundary>
        </HomePageContent>
      </PageWrapper>
    </>
  );
};

export default HomePage;

const OpenUploadDialogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

// TODO: remove bosch especial treatment after trial
const HomePageContent = styled(PageContent)<{ hasQuery?: boolean; adjustBosch?: boolean }>`
  ${({ hasQuery, adjustBosch }) =>
    hasQuery
      ? `
   padding: ${adjustBosch ? '5rem 11%' : '24px 48px'};
   width:${adjustBosch ? '100%' : '85%'};
   position:relative;
   margin:0 auto;
   @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: ${adjustBosch ? '5rem 24px' : '12px 24px'};
    width:100%;
  }
  `
      : `
  flex:1;
  display:flex;
  position:relative;

  align-items:center;
  justify-content:center;
`};
`;

const OpenUploadDialogCard = styled(Card)`
  height: auto;
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px 10px;
  background-color: ${({ theme }) => theme.white};
`;

const QuestHomepageText = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: 'Manrope';
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
  font-size: 1.2rem;
`;

const OpenUploadDialogText = styled.text`
  font-family: 'Roboto';
  color: ${({ theme }) => theme.primary};
  padding: 0 10px;
  text-decoration: bold;
  font-size: 14px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 10px;
  }
`;

const DialogButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 5px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const OpenUploadDialogButton = styled.button`
  width: fit-content;
  height: 40px;

  margin-top: 10px;

  border-width: 0;
  border-radius: 10px 10px;

  padding: 0 3px;

  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  cursor: pointer;

  font-size: 12px;

  &:hover {
    filter: brightness(80%);
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100px;
    height: 30px;
    font-size: 10px;
  }
`;

const HomeContent = styled.div<{ showComparison?: boolean }>`
  width: 100%;
  margin-right: auto;
  display: flex;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.singleColumn}px) {
    flex-direction: column;
    max-width: 600px;
  }
`;

interface ContentSectionProps {
  showComparison: boolean;
}

const SectionContentTitle = styled.h2`
  font-size: 28px;
  text-align: center;
`;

const LeftContent = styled.div<ContentSectionProps>`
  ${(props) => (props.showComparison ? 'width:50%;' : 'width:650px;')}

  & > div {
    ${(props) => (props.showComparison ? 'width:100%;' : 'width:100%;')}
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.singleColumn}px) {
    ${(props) => (props.showComparison ? '' : 'width:100%;')}
    & > div {
      width: 100%;
    }
  }

  padding: 20px;
  ${(props) => props.showComparison && 'border-right:1px solid black;'}
`;

const RightContent = styled.div`
  width: 50%;
  padding: 20px;
`;

const NoResults = styled.div`
  ${Heading2}
  display: flex;
  margin-top: 16px;
  padding-bottom: 24px;
`;

const ResultCount = styled.span`
  ${BodySmall}
  color: ${({ theme }) => theme.slate};
  padding-left: 20px;
`;

const SearchResultsArea = styled.div`
  display: flex;
  flex-direction: column;
`;

// TODO: remove bosch visuals after trial
const BoschLogoArea = styled.div`
  position: absolute;
  top: 2rem;
  left: 1.2rem;
  display: flex;
  align-items: center;

  & img {
    width: 10rem;
  }

  & span {
    color: ${(props) => props.theme.primary};
    font-size: 1.4rem;
    margin-left: 10px;
  }
`;

const BoschLineImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
