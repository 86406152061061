import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import { toast } from 'react-toastify';
import { AuthContext, AuthProviderProps } from '../../shared/context/AuthContext';
import { getText } from '../../shared/Texts';
import { QUEST_EVALUATION_FORM_URL } from '../../shared/Constants';
import {
  API_BASE,
  NEURALSEARCHX_EVALUATION_ENDPOINT,
  GLOBAL_TOAST_OPTIONS,
  LOCALSTORAGE_NSX_GIVEN_EVALUATION,
  TABLET_BREAKPOINT,
  TOAST_OPTIONS_LONG_NO_PROGRESS_BAR,
} from '../../shared/Constants';

import thumbsDownEmoji from '../../img/thumbsDownEmoji.png';
import thumbsUpEmoji from '../../img/thumbsUpEmoji.png';

interface EvaluationCardProps {
  title: string;
  setShowEvaluationCard: (s: boolean) => void;
}

const EvaluationCard = ({ title, setShowEvaluationCard }: EvaluationCardProps) => {
  const { getTokenAuthHeader, getResponse } = useContext(AuthContext) as AuthProviderProps;

  const language = navigator.language;

  const evaluation_values = [
    {
      value: 1,
      label: getText(language, 'UIEvaluationPositiveAnswer'),
      image: thumbsUpEmoji,
    },
    {
      value: 0,
      label: getText(language, 'UIEvaluationNegativeAnswer'),
      image: thumbsDownEmoji,
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleEvaluationCall = async (value: number, selected_index: number) => {
    const requestBody = {
      //create request body
      evaluation_value: value,
      interface: 'NeuralSearchX',
    };

    const response = await getResponse(
      `${API_BASE}${NEURALSEARCHX_EVALUATION_ENDPOINT}`,
      'POST',
      {
        ...(await getTokenAuthHeader()),
        'Content-Type': 'application/json',
      },
      JSON.stringify(requestBody),
    );

    if (response.status === 200) {
      setSelectedIndex(selected_index);
      //save in localstorage that the user already answered the evaluation
      localStorage.setItem(LOCALSTORAGE_NSX_GIVEN_EVALUATION, 'true');

      toast.success('Thanks, your feedback has been received.', TOAST_OPTIONS_LONG_NO_PROGRESS_BAR);
      // close the evaluation card
      setShowEvaluationCard(false);
    } else {
      toast.error(
        'Something went wrong, we could not register your feedback, please try again later',
        GLOBAL_TOAST_OPTIONS,
      );
    }
  };

  const handleEvaluationFormsRedirect = async () => {
    window.open(QUEST_EVALUATION_FORM_URL, '_blank');
  };
  return (
    <EvaluationContainer>
      <EvaluationCardArea variant="outlined">
        {title === 'Quest' && (
          <>
            {/* evaluation card that references a google form */}
            <EvaluationText>{getText(language, 'UIEvaluationFormCardText')}</EvaluationText>
            <CardButtonsArea>
              <EvaluationButton onClick={() => handleEvaluationFormsRedirect()}>
                {getText(language, 'UIEvaluationFormButtonText')}
              </EvaluationButton>
            </CardButtonsArea>
          </>
        )}
        {title !== 'Quest' && (
          <>
            {/* evaluation card with thumbs up/down buttons */}
            <EvaluationText>
              {getText(language, 'UIEvaluationQuestion') + ' ' + title + '?'}
            </EvaluationText>
            <CardButtonsArea>
              {evaluation_values.map((btnInfo, btnIndex) => (
                <EvaluationButton
                  key={btnInfo.label}
                  selected={selectedIndex === btnIndex}
                  onClick={() => handleEvaluationCall(btnInfo.value, btnIndex)}
                >
                  <EvaluationButtonEmote src={btnInfo.image} alt={btnInfo.label} />
                  {btnInfo.label}
                </EvaluationButton>
              ))}
            </CardButtonsArea>
          </>
        )}
      </EvaluationCardArea>
    </EvaluationContainer>
  );
};

export default EvaluationCard;

const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 2;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    position: static;
  }
`;

const EvaluationCardArea = styled(Card)`
  height: auto;
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  border-radius: 10px 10px;
  background-color: ${({ theme }) => theme.white};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 70vw;
    flex-direction: row;
    margin: 0 0 0.6rem 0;
    padding: 0.3rem;
  }
`;

const EvaluationText = styled.text`
  font-family: 'Roboto';
  color: ${({ theme }) => theme.primary};
  padding: 0 10px;
  text-decoration: bold;
  font-size: 1rem;
  text-align: center;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 0.8rem;
  }
`;

const CardButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 5px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

interface EvaluationButtonProps {
  selected?: boolean;
}

const EvaluationButton = styled.button<EvaluationButtonProps>`
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 10px 10px 0 10px;
  border-radius: 12px;
  background-color: ${({ theme, selected }) => (selected ? theme.secondary : theme.white)};
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 4px;
    margin: 3px;
    font-size: 0.8rem;
  }
`;

const EvaluationButtonEmote = styled.img`
  margin: 0 10px;
  width: 1rem;
`;
