// React
import React, { useState, useContext } from 'react';

//styling
import styled from 'styled-components';

//constants
import {
  API_BASE,
  SEARCH_FEEDBACK_ENDPOINT,
  LARGE_MOBILE_BREAKPOINT,
  GLOBAL_TOAST_OPTIONS,
} from '../../shared/Constants';

// contexts
import { AuthContext, AuthProviderProps } from '../../shared/context/AuthContext';

// Components
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

// button emotes images
import relevantEmote from '../../img/relevantEmote.png';
import onTopicEmote from '../../img/onTopicEmote.png';
import notRelevantEmote from '../../img/notRelevantEmote.png';

export interface FeedbackButtonsComponentProps {
  query: string;
  docID: string;
  pos: number;
  isReranked: boolean;
  searchedIndex: string;
}

export interface FeedbackRequestBody {
  query_text: string;
  is_reranked: boolean;
  searched_index: string;
  rank: number;
  unit_id: string;
  feedback_value: number;
}

const FeedbackButtonsComponent = ({
  query,
  docID,
  pos,
  isReranked,
  searchedIndex,
}: FeedbackButtonsComponentProps) => {
  const [selected, setSelected] = useState(-1);
  const [waitingServer, setWaitingServer] = useState(false);

  const { isKeycloakClient, getTokenAuthHeader, getResponse } = useContext(
    AuthContext,
  ) as AuthProviderProps;

  const handleButtonClick = async (id: number, value: number) => {
    if (id === selected) return;

    if (!isKeycloakClient) {
      toast.error('You must be logged in to annotate', GLOBAL_TOAST_OPTIONS);
      return;
    }

    try {
      setWaitingServer(true);
      const requestBody: FeedbackRequestBody = {
        //create request body
        query_text: query,
        is_reranked: isReranked,
        searched_index: searchedIndex,
        rank: 1 + pos, // convert to 1-indexed
        unit_id: docID,
        feedback_value: value,
      };

      const response = await getResponse(
        `${API_BASE}${SEARCH_FEEDBACK_ENDPOINT}`,
        'POST',
        {
          ...(await getTokenAuthHeader()),
          'Content-Type': 'application/json',
        },
        JSON.stringify(requestBody),
      );

      setWaitingServer(false);

      if (response.status === 201) {
        setSelected(id);
      } else {
        toast.error(
          'Something went wrong, we could not send your feedback, please try again later',
          GLOBAL_TOAST_OPTIONS,
        );
      }
    } catch {
      toast.error(
        'Something went wrong, we could not send your feedback, please try again later',
        GLOBAL_TOAST_OPTIONS,
      );
      setWaitingServer(false);
    }
  };

  const buttonInfo = [
    {
      label: 'Relevant',
      emote: relevantEmote,
      value: 2,
    },
    {
      label: 'On Topic',
      emote: onTopicEmote,
      value: 1,
    },
    {
      label: 'Not Relevant',
      emote: notRelevantEmote,
      value: 0,
    },
  ];

  return (
    <FeedbackButtonsContainer>
      {waitingServer && <FeedbackCircularProgress />}
      {buttonInfo.map((btnInfo, btnIndex) => {
        return (
          <FeedbackButton
            key={btnInfo.label}
            selected={selected === btnIndex}
            onClick={() => handleButtonClick(btnIndex, btnInfo.value)}
          >
            <FeedbackButtonEmote src={btnInfo.emote} alt={btnInfo.label} />
            <span>{btnInfo.label}</span>
          </FeedbackButton>
        );
      })}
    </FeedbackButtonsContainer>
  );
};

export default FeedbackButtonsComponent;

const FeedbackButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  margin: auto;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const FeedbackCircularProgress = styled(CircularProgress)`
  width: 30px !important;
  height: 30px !important;
  align-self: center !important;
  color: ${({ theme }) => theme.primary} !important;

  & .MuiCircularProgress-colorPrimary {
    color: #64b53f !important;
  }
`;

interface FeedbackButtonProps {
  selected: boolean;
}

const FeedbackButton = styled.button<FeedbackButtonProps>`
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 10px 10px 0 10px;
  border-radius: 20px;
  background-color: ${({ theme, selected }) => (selected ? theme.secondary : theme.white)};
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }

  @media only screen and (max-width: ${LARGE_MOBILE_BREAKPOINT}px) {
    background-color: ${({ theme, selected }) => (selected ? theme.secondary : theme.white)};
  }
`;
const FeedbackButtonEmote = styled.img`
  margin: 0 10px;
`;
